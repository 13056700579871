import React from 'react';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import example_year_side_by_side from '../../../../../../../common/src/assets/image/2020/2_years_side_by_side.png';
import example_year_stacked from '../../../../../../../common/src/assets/image/2020/2_years_stacked.png';
import template_options_year from '../../../../../../../common/src/assets/image/template_options_2_years.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        The 2 Years/Page template style generates a calendar showing two years on a single page. Each day can be color-coded based on the first event or task occurring on that date. Two variations of this template style are included with PrintableCal. One
        version shows 2 years side-by-side and the other version shows 2 years stacked.
      </p>
      <h3>2 Years Side-by-Side</h3>
      <p>
        <Image alt="" src={example_year_side_by_side} style={{ width: 584 }} />
      </p>
      <h3>2 Years Stacked</h3>
      <p>
        <Image alt="" src={example_year_stacked} style={{ width: 877 }} />
      </p>
      <h2>Template Settings</h2>
      <p>
        The 2 Years/Page template provides the following options when creating a calendar...
      </p>
      <Image alt="" src={template_options_year} style={{ width: 448 }} />
      <br />
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Starting year</strong>
            </td>
            <td>The first year to be printed.</td>
          </tr>
          <tr>
            <td>
              <strong>First day of week</strong>
            </td>
            <td>
              The first day of the week will be the left-most day column in each
              month.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Time format</strong>
            </td>
            <td>
              The time format. Supported formats are 12-hour, 24-hour,
              Shortened, and Military, and Numeric. An example time is shown for
              each format in the drop-down list.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Color dates</strong>
            </td>
            <td>
              <span>
                When checked, each date will be color-coded to match calendar
                associated with the first event or task occurring on that date.
                Note, you can hover over a date to see all the events and tasks
                it contains.
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Templates-and-Options/Year-2-Per-Page"
      commentsId="commentsplus_2_years"
      title="2 Years/Page Template"
      description="The 2 Years/Page template style generates a printable calendar showing 2 years on a single page. Each day can be color-coded based on the first event or task occurring on that date."
      keywords="year template, 2 years on a page, 2020 template, calendar template, print 2020, print calendar, print holidays, 2020 holidays, Excel calendar, Word calendar"
      content={content}
    />
  );
};

export default Documentation;
